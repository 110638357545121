import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import PagingController from "../../../../components/PagingController/PagingController";

const Documents = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations

	return (
		<>
			<article className="temp09_info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<div className="content-container">
						<div className="office_inner">
							<h2 className={"large bold"}>
								放射温度計は、なぜ、触れずに測れるのだろう。
							</h2>
							<h2 class="temp02_font_title medium">
								赤外線エネルギーを測定することで、物体の温度を測定することができます。
							</h2>
							<div>
								<div className="flex-container">
									<div className="temp03_infrared">
										<img
											className="temp06_image"
											src="/asset/chart_a.gif"
											width="300"
											hight="300"
											alt="グラフ"
										/>
									</div>
									<div className="temp03_flex2">
										<ul className="temp03_ul_flex">
											<li className="temp03_flex2">
												<p>
													赤外線エネルギーを測定することで、物体の温度を測定することができます。
												</p>

												<p>
													図Aは、物体の温度と放射される赤外線の強さ、そして赤外線と波長との関係を立体的に表したものです。
												</p>

												<p>
													この図Aをもとに、ある波長(a)の断面を見たものが、図Bです。
												</p>

												<p>
													温度が高くなるにしたがって、赤外線エネルギーが急激に増加することがわかります。
												</p>

												<p>
													そして、ある特定の波長をあらかじめ決めてその波長の赤外線エネルギーの強さを測定すれば、その物体の温度を知ることができます。
												</p>

												<p>これが赤外線を利用した温度測定の原理です。</p>
											</li>
										</ul>
									</div>
								</div>
								<div className="flex-container">
									<div className="temp03_infrared">
										<img
											className="temp06_image"
											src="/asset/chart_b.gif"
											width="300"
											hight="300"
											alt="グラフ"
										/>
									</div>
									<div className="temp03_flex2">
										<ul className="temp03_ul_flex">
											<li className="temp03_flex2">
												<p>
													放射温度計は上記の原理により温度測定が可能ですが、
												</p>

												<p>
													赤外線エネルギーが弱い物体を測定する場合（温度が低く、長波長測）でも波長域を少し広げ（例えば8～14μmの範囲）、
												</p>

												<p>
													その波長域間の多くの赤外線エネルギーを集めることによって温度測定ができるようになっています。
												</p>
											</li>
										</ul>
									</div>
								</div>
								<div className="temp06_border"></div>
							</div>
							<h2 class="temp02_font_title medium">
								正確に温度を測るためには、大気の吸収を考慮する必要があります。
							</h2>
							<div>
								<div className="flex-container">
									<div className="temp03_infrared">
										<img
											className="temp06_image"
											src="/asset/chart_c.gif"
											width="300"
											hight="300"
											alt="グラフ"
										/>
									</div>
									<div className="temp03_flex2">
										<ul className="temp03_ul_flex">
											<li className="temp03_flex2">
												<p>
													図Aをもとに、ある温度(Ta)の断面、図C*を見て下さい。
												</p>

												<p>
													この図の黄色の部分の全面積を求めても温度がわかります。
												</p>

												<p>
													ところが大気中にある水蒸気や炭酸ガス(二酸化炭素)はある特定の波長域の赤外線エネルギーを吸収する性質があります。
												</p>

												<p>
													そのための測定物体までの距離などによって赤外線エネルギーを吸収する度合いが変わり、全波長域の赤外線エネルギーを検出することができません。
												</p>

												<p>
													さらに放射温度計のセンサーでは赤外線に対する感度が全波長域にわたって広くとれるなどの理由で、
												</p>

												<p>
													現実には図Dのように特定の波長域(λb)*(注)を選んで測定します。
												</p>
											</li>
										</ul>
									</div>
								</div>
								<div className="flex-container">
									<div className="temp03_infrared">
										<img
											className="temp06_image"
											src="/asset/chart_d.gif"
											width="300"
											hight="300"
											alt="グラフ"
										/>
									</div>
									<div className="temp03_flex2">
										<ul className="temp03_ul_flex">
											<li className="temp03_flex2">
												<p>
													つまり、波長と赤外線エネルギーの関係から、全波長の赤外線エネルギーを測定しなくても特定の波長域の赤外線エネルギー量を測定すれば、
												</p>

												<p>温度として変換できるのです。</p>

												<a
													href="/support/documents/about-temperature/temp10#stefan-boltzmann_law"
													className="temp01_absolutetemperature"
												>
													*ステファン・ボルツマンの法則 （温度計一口メモ）
												</a>

												<p>
													*(注)放射線温度計にはセンサーの前部に、ある特定の波長域の赤外線エネルギーだけを通すフィルターが内蔵されています。
												</p>
											</li>
										</ul>
									</div>
								</div>
								<div className="temp06_border"></div>
							</div>
						</div>
					</div>
					<PagingController current={6} next={'/support/documents/about-temperature/temp07'} prev={'/support/documents/about-temperature/temp05'} />
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Documents;
